import React from "react";
import Seo from "../components/Seo";
import Layout from "../components/layout";
import InstagramFeed from "../components/InstagramFeed";
import OurLocations from "../sections/OurLocations";
import Workouts from "../sections/Workouts";
import { StaticImage } from "gatsby-plugin-image";
import { useModal } from "../../provider";
import { gaEvent } from "../helpers";

export default function HomePage() {
    const modal = useModal();
    return (
        <Layout>
            <Seo
                title="FEAT Fitness"
                meta_title="Fun Community Outdoor Training | FEAT Fitness"
                meta_desc="FEAT Fitness brings authentic human connection into peoples world's through fun community outdoor training."
                slug=""
            />
            <main>
                <div>
                    {/* Hero card */}
                    <div className="relative">
                        <div className="mx-auto">
                            <div className="relative shadow-xl sm:overflow-hidden">
                                <div className="absolute inset-0">
                                    <StaticImage
                                        className="h-full w-full object-cover object-center	"
                                        src="../../static/img/generic-sydney-outdoor-cover.jpg"
                                        alt="FEAT Training in Balmoral"
                                        loading="eager"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-gray-100 to-gray-400 mix-blend-multiply" />
                                </div>
                                <div className="relative uppercase px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8 text-white">
                                    <div className="text-center text-3xl font-medium sm:text-3xl lg:text-4xl block">
                                        FEAT Fitness Provides
                                    </div>
                                    <h1 className="text-center text-4xl font-bold sm:text-5xl lg:text-6xl block">
                                        Fun Community Outdoor Training
                                    </h1>
                                    <p className="mt-6 max-w-lg mx-auto text-center font-thin text-xl text-white italic sm:max-w-3xl">
                                        We're more than just a bootcamp
                                    </p>
                                    <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center sm:flex-col">
                                        <div className="space-y-4 sm:space-y-4 sm:mx-auto sm:inline-grid">
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    gaEvent(
                                                        "Button",
                                                        "FreeTrial",
                                                        "free trial - home hero to modal"
                                                    );
                                                    modal.openFreeTrialModal();
                                                }}
                                                className="flex items-center justify-center px-4 py-3 border border-transparent text-l font-medium rounded-md shadow-sm text-white bg-feat-orange hover:bg-feat-orange-50 sm:px-8"
                                            >
                                                Start your Free Week
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Logo cloud */}
                    <div className="bg-gray-100 -mb-4">
                        <div className="max-w-screen-lg mx-auto py-12 px-4 sm:px-6 lg:px-8">
                            <h2 className="text-center text-xl text-header font-semibold uppercase text-gray-500 tracking-wide pb-4">
                                Training with FEAT Fitness
                            </h2>
                            <p className="text-center text-base text-gray-500 pb-4">
                                Our core purpose is to bring authentic human
                                connection back into peoples lives.
                            </p>
                            <p className="text-center text-base text-gray-500 pb-4">
                                We train outdoors into the fresh air with other
                                members of your local community to keep fit and
                                healthy, that is what FEAT is all about. Our
                                training sessions offer a variety of equipment
                                and fun workouts to keep you motivated, you'll
                                never do the same session twice!
                            </p>
                            <p className="text-center text-base text-gray-500">
                                At a FEAT session, you'll be interacting with
                                humans (not TV Screens) and be able to get
                                assisted feedback from your skilled trainer as
                                you go, as well as a bunch of laughs as you
                                train alongside other like-minded individuals
                                who become like your second family. This second
                                family drives you towards your goals and leaves
                                you feeling full of energy and warmth after each
                                session.
                            </p>
                        </div>
                    </div>
                </div>

                {/* More main page content here... */}
                <Workouts />
                <OurLocations />
                {/* The Power of Section */}
                <div className="relative mt-20">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div
                                aria-hidden="true"
                                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
                            >
                                <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                                <svg
                                    className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect
                                                x={0}
                                                y={0}
                                                width={4}
                                                height={4}
                                                className="text-gray-200"
                                                fill="currentColor"
                                            />
                                        </pattern>
                                    </defs>
                                    <rect
                                        width={404}
                                        height={392}
                                        fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                                    />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                {/* Testimonial card*/}
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <StaticImage
                                        className="absolute-important inset-0 h-full w-full object-cover"
                                        imgClassName="absolute inset-0 h-full w-full object-cover"
                                        objectFit="cover"
                                        src="../../static/img/BartonTanner-Founder.jpg"
                                        alt="Barton Tanner - Founder of FEAT Fitness"
                                    />
                                    <div className="absolute inset-0 bg-rose-500 mix-blend-multiply" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-rose-600 via-rose-600 opacity-90" />
                                    <div className="relative px-8">
                                        <blockquote className="mt-8">
                                            <div className="relative text-lg font-medium text-white md:flex-grow">
                                                <svg
                                                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-rose-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 32 32"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                </svg>
                                                <p className="relative pl-8 italic">
                                                    Starting FEAT Fitness for me
                                                    was about bringing people
                                                    back together in the outdoor
                                                    environment. Now more than
                                                    ever, it's important for
                                                    people to not only keep fit
                                                    and healthy, but interact in
                                                    a safe environment with
                                                    their local community. That
                                                    is what FEAT Fitness is all
                                                    about.
                                                </p>
                                            </div>

                                            <footer className="mt-4">
                                                <p className="text-base font-semibold text-white">
                                                    Barton Tanner, Founder of
                                                    FEAT Fitness
                                                </p>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            {/* Content area */}
                            <div className="py-16 lg:pt-20">
                                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                    The Power of Outdoor Training Communities{" "}
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                        As we start to come out of our lockdowns
                                        and learn to live with COVID, it's
                                        important that we turn our attention to
                                        health. Health is a state of complete
                                        physical, mental, and social well-being
                                        and not merely the absence of disease or
                                        infirmity.
                                    </p>
                                    <p className="text-base leading-7">
                                        Training at FEAT Fitness you will get a
                                        great workout that will keep you fit and
                                        work you towards your fitness goals.
                                        Additionally, you get to do this with an
                                        amazing group of like-minded members of
                                        your local community, and outdoors in
                                        the fresh air!
                                    </p>
                                    <p className="text-base leading-7">
                                        There is truly no better way to start
                                        your day, and work on all aspects of
                                        your own health.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End the power of section */}

                <InstagramFeed className="bg-feat-blue-200" />
            </main>
        </Layout>
    );
}
